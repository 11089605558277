import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';

interface ITempData {
  text: string;
  class: string;
  icon: string;
}

@Component({
  selector: 'scp-btn',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RouterModule, MatRippleModule, TranslateModule]
})
export class ButtonComponent implements OnChanges, OnInit {

  @Input()
  public disabled = false;

  @Input()
  public text: string;

  @Input()
  public action = 'submit';

  @Input()
  public tabindex: string;

  @Input()
  public width: number;

  @Input()
  public height = 60;

  @Input()
  public url: string;

  @Input()
  public align: string | null = null;

  @Input()
  public href: string | null = null;

  @Input()
  public hasMarginTop = true;

  @Input()
  public mobileHeight = false;

  @Input()
  public isDispatching = false;

  @Input()
  public isSimpleDispatching = false;

  @Input()
  public skipCustomClass = false;

  @Input()
  public target: string = '_self';

  public imgSrc: string;
  public clazz: string | null;
  private tempProps: ITempData;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
  }

  @Input()
  public set icon(icon: string) {
    if (icon) {
      this.imgSrc = (icon.startsWith('http') || icon.startsWith('/uploads')) ? icon : '/assets/images/svg/' + icon + '.svg';
      this.changeDetectorRef.markForCheck();
    }
  }

  @Input()
  public set type(type: number | string) {
    this.clazz = type ? `btn-type-${type}` : 'btn-type-2';
    this.changeDetectorRef.markForCheck();
  }


  public ngOnInit() {
    this.tempProps = { text: this.text, class: this.clazz, icon: this.imgSrc };
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.isDispatching) {
      this.manageSubmitBtn(changes.isDispatching.currentValue);
    }
  }

  private manageSubmitBtn(isDispatching: boolean): void {
    if (isDispatching) {
      this.tempProps = { text: this.text, class: this.clazz, icon: this.imgSrc };
      if (!this.skipCustomClass) {
        this.clazz = this.clazz !== 'btn-type-9' ? 'btn-type-6' : 'btn-type-10';
      }
      this.text = this.isSimpleDispatching ? '' : 'BUTTON.PLEASE_WAIT';
      this.icon = 'loading';
    } else if (this.tempProps) {
      this.clazz = this.tempProps.class;
      this.text = this.tempProps.text;
      this.imgSrc = this.tempProps.icon;
    }
    this.changeDetectorRef.markForCheck();
  }

}
