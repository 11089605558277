import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { ConfigService, Product } from '@scpc/modules/common/services/config.service';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import Swiper from 'swiper';
import { Idle } from '@scpc/modules/common/services/request-idle-callback';
import { catchError } from '@scpc/utils/dom.utils';
import { filter } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'scp-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, MatButtonModule, RouterModule],
})
export class ProductsComponent implements OnInit, AfterContentInit, OnDestroy {

  @Input()
  public type: 'mobile' | 'desktop';

  protected readonly products: Product[] = this.configService.products.filter((p: Product): boolean => p.hidden !== true);
  protected index: number = -1;
  protected reachBeginning = true;
  protected reachEnd = true;
  protected swiper: Swiper;
  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    public readonly router: Router,
    private readonly configService: ConfigService,
    private readonly zone: NgZone,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly idle: Idle,
    @Inject(PLATFORM_ID) private readonly platformId: string,
  ) {
  }

  public ngOnInit(): void {
    this.reachEnd = this.type === 'desktop';
    this.scrollToProduct(false);
  }

  public ngAfterContentInit(): void {
    this.router.events
      .pipe(filter(/* istanbul ignore next */event => event instanceof NavigationEnd), takeUntilDestroyed(this.destroyRef))
      .subscribe(/* istanbul ignore next */() => this.scrollToProduct(true));
    this.idle.requestIdleCallback(() => {
      this.swiper = new Swiper('.swiper-products-' + this.type, {
        initialSlide: this.index,
        touchRatio: 1.5,
        speed: 400,
        resistance: true,
        resistanceRatio: 0.75,
        centeredSlides: true,
        centeredSlidesBounds: true,
        slidesPerView: 'auto',
        watchSlidesProgress: true,
        resizeObserver: true,
        updateOnWindowResize: true,
        centerInsufficientSlides: false,
        on: {
          afterInit: /* istanbul ignore next */ (swiper: Swiper) => this.updateSlider(swiper),
          reachBeginning: /* istanbul ignore next */ (swiper: Swiper) => this.updateSlider(swiper),
          reachEnd: /* istanbul ignore next */ (swiper: Swiper) => this.updateSlider(swiper),
          resize: /* istanbul ignore next */ (swiper: Swiper) => {
            this.updateSlider(swiper);
            swiper.slideTo(this.index);
          },
          setTranslate:  /* istanbul ignore next */(swiper: Swiper, translate: number) => {
            if (!Number.isNaN(translate)) {
              this.updateSlider(swiper);
            }
          },
        },
      });
    });
  }

  public ngOnDestroy(): void {
    /* istanbul ignore else */
    catchError(() => this.swiper?.destroy());
  }

  public trackByFn(index: number, category: { link: string; name: string }): string {
    return category.name;
  }

  /* istanbul ignore next */
  private updateSlider(swiper: Swiper): void {
    const hasOverflow: boolean = Math.floor((swiper as any).virtualSize) > swiper.width;
    this.reachBeginning = swiper.isBeginning || !hasOverflow;
    this.reachEnd = swiper.isEnd || !hasOverflow;
    this.zone.run(() => this.changeDetectorRef.markForCheck());
  }

  private scrollToProduct(scroll: boolean): void {
    for (let i = 0; i < this.products.length; i++) {
      const product = this.products[i];
      if (product.link.startsWith('/') && this.router.isActive(product.link, {
        paths: product.link === '/' ?  /* istanbul ignore next */ 'exact' : 'subset',
        queryParams: 'subset',
        fragment: 'ignored',
        matrixParams: 'ignored',
      })) {
        /* istanbul ignore next */
        if (scroll && isPlatformBrowser(this.platformId)) {
          this.zone.run(() => {
            this.swiper?.slideTo(i, 400, false);
            this.changeDetectorRef.markForCheck();
          });
        } else {
          this.index = i;
        }
        break;
      }
    }
  }

}
