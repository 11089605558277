<div class="scp-header-cookies">
  {{ 'COOKIES_DESCRIPTION' | translate }}
  <div class="scp-header-cookies-actions">
    <scp-btn
      id="show-more"
      type="4"
      width="100"
      height="40"
      [hasMarginTop]="false"
      text="MORE_INFO"
      (click)="showMore()"
    ></scp-btn>
    <scp-btn
      id="accept"
      type="2"
      width="100"
      height="40"
      [hasMarginTop]="false"
      text="OK"
      (click)="acceptCookies()"
    ></scp-btn>
  </div>
</div>
