import { EventEmitter, Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { TourTippyService } from '@scpc/modules/common/tour/tippy-tour.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { TourTippyStepOption } from '@scpc/modules/common/tour/tippy-step-option';
import { Draw } from '@scpc/modules/lucky-numbers/dto/draw';
import { getTours } from '@scpc/modules/lucky-numbers/tours/tours';
import { TourState } from 'ngx-ui-tour-core';

export interface ScpTour {
  name: string;
  getTourSteps: () => Promise<TourTippyStepOption[]>;
}

@Injectable({ providedIn: 'root' })
export class ScpTourService {

  private static readonly DEFAULT = 'default';

  public update: EventEmitter<void> = new EventEmitter();

  private d: Draw;
  private readonly destroy: Subject<void> = new Subject<void>();
  private tour: ScpTour;
  private tourName: string;

  constructor(private readonly tourService: TourTippyService,
              private readonly router: Router,
              private readonly zone: NgZone,
              @Inject(PLATFORM_ID) private readonly platformId: string,
              @Inject(DOCUMENT) private readonly document: Document) {
    this.tourService.end$.subscribe(/* istanbul ignore next */(): void => {
      this.tour = null;
      this.tourName = null;
    });
  }

  public get name(): string | null {
    return this.tourName;
  }

  public get draw() {
    return this.d;
  }

  public set draw(draw: Draw) {
    this.d = draw;
    this.update.next();
  }

  private static getTourKey(name: string) {
    return 'tour:' + name;
  }

  private static getDefaultTourKey() {
    return 'tour:' + ScpTourService.DEFAULT;
  }

  public async showTourIfNotCompleted(tour: ScpTour): Promise<void> {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.destroy.next();
    this.tourService.pause();
    this.tour = tour;
    this.tourName = tour.name;
    this.tourService.initialize(await tour.getTourSteps(), { isAsync: true });
    this.tourService.startAt(this.getNextStepId().stepId);
  }

  public isDefaultTourAvailable() {
    return isPlatformBrowser(this.platformId) && !localStorage.getItem(ScpTourService.getDefaultTourKey());
  }

  public showDefaultTour(restore?: boolean): void {
    this.showDefault(ScpTourService.getDefaultTourKey(), {
      anchorId: 'default.step-1',
      content: 'TOUR.STEPS.GENERAL',
      appendTo: /* istanbul ignore next */ () => this.document.body,
      placement: 'bottom',
    }, restore);
  }

  public showCartTour(restore?: boolean): void {
    if (localStorage.getItem(ScpTourService.getDefaultTourKey())) {
      this.hideDefaultTour();
      this.showDefault(ScpTourService.getTourKey('cart'), {
        anchorId: 'ln.step-15',
        content: 'TOUR.STEPS.CART',
        appendTo: /* istanbul ignore next */ () => this.document.getElementsByTagName('mat-sidenav-content')[0],
      }, restore);
    }
  }

  public showDefault(name: string, step: TourTippyStepOption, restore?: boolean): void {
    /* istanbul ignore if */
    if (!isPlatformBrowser(this.platformId) || (!restore && !!localStorage.getItem(name))) {
      return;
    }
    this.destroy.next();
    this.tourService.pause();
    this.tourService.initialize([step], { isAsync: true });
    this.tourName = name;
    this.tourService.start();
    this.tourService.pause$.pipe(takeUntil(this.destroy)).subscribe(() => localStorage.setItem(name, 'completed'));
  }

  public hideDefaultTour(): boolean {
    if (!isPlatformBrowser(this.platformId) || this.tourName !== ScpTourService.getDefaultTourKey()) {
      return false;
    }
    const pause = this.tourService.getStatus() === TourState.PAUSED;
    this.destroy.next();
    this.tourService.end();
    localStorage.setItem(ScpTourService.getDefaultTourKey(), 'completed');
    return !pause;
  }

  public hideCartTour(): boolean {
    const name = ScpTourService.getTourKey('cart');
    if (!isPlatformBrowser(this.platformId) || this.tourName !== name) {
      return false;
    }
    this.destroy.next();
    this.tourService.end();
    return true;
  }

  public getTours(route: string): ScpTour[] {
    return [...getTours(route, this.document, () => this.draw)];
  }

  private getNextStepId(): TourTippyStepOption {
    const url = this.router.url.split('?')[0];
    return this.tourService.steps.find(step => url.match(step.url));
  }

}
