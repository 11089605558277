import { Directive, ElementRef, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[scpRouteTransformer]',
  standalone: true
})
export class RouteTransformerDirective {

  private readonly host: string | null;

  constructor(private readonly elementRef: ElementRef,
              private readonly router: Router,
              @Inject(PLATFORM_ID) private readonly platformId: string) {
    this.host = isPlatformBrowser(platformId) ? location.protocol + '//' + location.host : null;
  }

  @HostListener('click', ['$event'])
  public onClick(event) {
    if (event.target.tagName === 'A') {
      const href = event.target.getAttribute('href');
      const target = event.target.getAttribute('target');
      const absolute = this.host && href && href.startsWith(this.host);
      /* istanbul ignore else */
      if (target !== '_blank' && href  && ((!href.startsWith('http') || absolute) && !href.startsWith('tel:') && !href.startsWith('mailto:'))) {
        this.router.navigate(absolute ? [href.replace(this.host, '')] : [href]);
        event.preventDefault();
      }
    }
  }
}
