import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  inject,
  OnInit,
  Optional,
} from '@angular/core';
import { LayoutService } from '@scpc/modules/common/services';
import { CommonModule } from '@angular/common';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { Router } from '@angular/router';
import { iOSVersion } from '@scpc/utils/io';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'scp-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ContentLoaderModule],
})
export class SkeletonLoaderComponent implements OnInit {

  protected isMobile: boolean;
  protected baseUrl: string = '';

  private layoutService: LayoutService = inject(LayoutService);
  private route: Router = inject(Router);
  private platform: Platform = inject(Platform);

  constructor(@Optional() @Inject('USER_AGENT') private readonly userAgent: string) {
  }


  public ngOnInit(): void {
    this.isMobile = !this.layoutService.isDesktop();
    const userAgent: string = this.userAgent || navigator.userAgent;
    /* istanbul ignore next */
    if (this.platform.SAFARI && iOSVersion(userAgent)?.major < 14) {
      this.baseUrl = this.route.url;
    }
  }

}
