import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class RouterService {
  private previous: string | null = null;
  private current: string | null = null;

  constructor(private readonly router: Router) {
    this.current = router.url;
    router.events.subscribe((event): void => {
      if (event instanceof NavigationEnd) {
        this.previous = this.current;
        this.current = event.url;
      }
    });
  }

  public get currentUrl(): string {
    return this.current;
  }

  public get previousUrl(): string | null {
    return this.previous;
  }
}
