<ngx-loading-bar color='#ff7922' [includeSpinner]='false' ref='http'></ngx-loading-bar>
<div *ngIf='loader.value$ | async as value' class='overlay'></div>
<scp-header-cookies *ngIf='showCookiesHeader && (update.available | async) === null'
                    (accepted)='acceptCookies()'></scp-header-cookies>
<scp-header-update *ngIf='(update.available | async) !== null'></scp-header-update>
<scp-header
  (toggleSidenav)='toggleSidenav()'
  [menuButtonHidden]='!isAuthorized'
  [toursDisabled]='isVisibleSkeletonLoader'
  (tour)='showTour($event)'
  (hideTour)='hideTour()'
  [cart]='!isFloatCart'
  [tours]='tours'
  [customer]='customer'
  [balance]='balance'
  [class]='headerClass'>
</scp-header>
<mat-sidenav-container [hasBackdrop]='!isWeb && isBrowser' class='scp-sidenav-container' [class.auth]='isAuthorized'>
  <mat-sidenav
    #sidenav
    class='scp-sidenav'
    [class.ssr]='!isBrowser && isAuthorized'
    [mode]="isWeb || !isBrowser ? 'side' : 'over'"
    [opened]='((!isBrowser || isWeb) && isAuthorized && isLoaded)'
    [autoFocus]='false'>
    <div class='scp-scrollbar scp-dashboard-menu' *ngIf='isAuthorized'>
      <div *ngIf='!isWeb && isBrowser' class='scp-logo'>
        <div>
          <img src='{{logo}}' alt='' width='80' loading='lazy' />
        </div>
        <button mat-icon-button class='scp-menu-close' (click)='sidenav.toggle()'>
          <img src='/assets/images/svg/close.svg' alt='' height='16' width='16' class='logo' loading='lazy' />
        </button>
      </div>
      <div *ngIf='!isWeb && isBrowser' class='scp-divider'></div>
      <mat-nav-list class='scp-navigation-menu'>
        <div class='scp-navigation-category scp-auto-height'>
          <a
            class='scp-user-block'
            mat-list-item
            (click)='closeSidenav()'
            [routerLinkActive]="'active'"
            [routerLink]="'/account/profile'"
            [routerLinkActiveOptions]='{ exact: true }'
          >
            <img src='/assets/images/svg/profile.svg' alt='' height='24' width='24' class='logo' loading='lazy' />
            <div class='scp-user-data'>
              <div class='scp-username'>
                <ng-container *ngIf='isLoaded && customer'>
                  {{ customer.firstName + ' ' + customer.lastName }}
                </ng-container>
              </div>
              <div class='scp-balances' *ngIf='balance'>
                <div>
                  <div class='scp-cash-balance-title'>
                    {{ 'TOTAL_BALANCE' | translate }}
                  </div>
                  <div class='scp-cash-balance'>
                    {{ balance.TOTAL_CASH[0] | formatMoney }}
                  </div>
                </div>
                <div>
                  <div class='scp-cash-balance-title'>
                    {{ 'BONUS_BALANCE' | translate }}
                  </div>
                  <div class='scp-bonus-balance'>
                    {{ balance.BONUS[0] | formatMoney }}
                  </div>
                </div>
                <div>
                  <div class='scp-cash-balance-title'>
                    {{ 'AVAILABLE_TO_WITHDRAWAL_BALANCE' | translate }}
                  </div>
                  <div class='scp-balance-withdraw-value'>
                    {{ balance.CASH[0] | formatMoney }}
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class='scp-divider'></div>
        <div
          *ngFor='let category of categories; trackBy: categoryTrackBy'
          [class.scp-navigation-category-opened]='!category.closed'
          class='scp-navigation-category'
        >
          <a
            mat-list-item
            [routerLinkActive]="!category.items?.length ? 'active' : ''"
            [routerLink]='category.routerLink || activatedRoute.snapshot.url'
            [queryParams]='category.queryParams'
            [skipLocationChange]='!!category.items?.length'
            (click)='category.items?.length ? updateMenuState(category, categoryRef, $event) : closeSidenav()'
          >
            <img [src]="category.logo ? '/assets/images/svg/' + category.logo +'.svg' : ''"
                 [style.opacity]='category.logo ? 1: 0'
                 alt=''
                 height='24'
                 width='24'
                 class='logo'
                 loading='lazy' />
            <span class='title'>{{ category.title | translate }}</span>
            <span *ngIf='category.items?.length' [class.scp-category-arrow-rotated]='!category.closed' class='more'>
              <img
                src='/assets/images/svg/menu-arrow.svg'
                alt=''
                height='16'
                width='16'
                class='scp-category-arrow'
                loading='lazy'
              />
            </span>
          </a>
          <div #categoryRef class='scp-navigation-category-items' [class.scp-closed]='category.closed'>
            <ng-container *ngFor='let item of category.items; trackBy: menuTrackBy'>
              <scp-dashboard-menu-item
                *ngIf='item.isActive'
                [url]='item.routerLink'
                [queryParams]='item.queryParams'
                [title]='item.title'
                [icon]='item.icon'
                [iconSize]='item.iconSize'
                [count]='item.count'
                (click)='closeSidenav()'
                tabindex='-1'
              >
              </scp-dashboard-menu-item>
            </ng-container>
          </div>
          <div class='scp-divider'></div>
        </div>
        <div class='scp-fill-space' #space>
          <div class='scp-divider' [style.display]="space.offsetHeight > 1 ? 'flex' : 'none'"></div>
        </div>
        <div class='scp-sign-out-block scp-navigation-category'>
          <a mat-list-item [routerLink]='signOutURL' (click)='signOut()'>
            <img src='/assets/images/svg/sign-out.svg' alt='' height='24' width='24' class='logo' loading='lazy' />
            {{ 'SIGN_OUT' | translate }}
          </a>
        </div>
      </mat-nav-list>
    </div>
  </mat-sidenav>
  <mat-sidenav-content [class.ssr]='isAuthorized'>
    <div class='scp-content'>
      <div class='scp-scrollbar-content' #dashboardScrollingBlock
           (onResize)='onResize(dashboardScrollingBlock.scrollTop)'>
        <div class='scp-products'>
          <scp-products type='mobile'></scp-products>
        </div>
        <scp-smart-banner *ngIf='smartBannerConfig?.enabled && !isVisibleSkeletonLoader'
                          [config]='smartBannerConfig'
                          [logo]='smallLogo'
                          [siteName]='configService.siteName'
                          (close)='smartBannerConfig = null'>
        </scp-smart-banner>
        <div class='scp-fica' *ngIf='customer?.status === "DUPLICATE_ID" && !this.isVisibleSkeletonLoader'>
          <div>
            <div class='scp-text'>
              <div class='scp-notification-error'></div>
              <div>
                <span>{{'Account with this Gov. ID already exists. Please contact customer support.' | translate }}</span>
              </div>
            </div>
            <scp-btn
              [hasMarginTop]='false'
              text='Livechat'
              height='36'
              type='1'
              width='73'
              (click)='document.querySelector(".scp-header__chats")?.click()'>
            </scp-btn>
          </div>
        </div>
        <div *ngIf='kycRequired || (documentsRequired | async)' class='scp-fica'>
          <ng-container [ngSwitch]='customer.kyc?.status'>
            <div *ngSwitchCase='"IN_PROGRESS"'>
              <div class='scp-text info'>
                <div class='scp-notification-info'></div>
                {{ 'Documents verification is in progress. Usually it might take up to 15 min' | translate }}
              </div>
            </div>
            <div *ngSwitchCase='"INVALID"'>
              <div class='scp-text'>
                <div class='scp-notification-error'></div>
                <div>
                  {{ customer.kyc.reason }}
                  <ng-container
                    *ngIf='(customer.forbidden?.includes("kyc") || customer.forbidden?.includes("kyc-nr")) && (customer.forbidden?.includes("ekyc") || customer.ekyc?.status === "CONFIRMED"); else tryAgain'>
                    <span>Contact our support via livechat</span>
                  </ng-container>
                  <ng-template #tryAgain>
                    <span>Please verify once again</span>
                  </ng-template>
                </div>
              </div>
              <ng-container
                *ngIf='(customer.forbidden?.includes("kyc") || customer.forbidden?.includes("kyc-nr")) && (customer.forbidden?.includes("ekyc") || customer.ekyc?.status === "CONFIRMED"); else tryAgainAction'>
                <scp-btn
                  [hasMarginTop]='false'
                  text='Livechat'
                  height='36'
                  type='1'
                  width='73'
                  (click)='document.querySelector(".scp-header__chats")?.click()'>
                </scp-btn>
              </ng-container>
              <ng-template #tryAgainAction>
                <scp-btn
                  *ngIf='!((customer.forbidden?.includes("kyc") || customer.forbidden?.includes("kyc-nr")) && (customer.forbidden?.includes("ekyc") || customer.ekyc?.status === "CONFIRMED"))'
                  [hasMarginTop]='false'
                  text='VERIFY'
                  height='36'
                  type='1'
                  width='73'
                  [routerLink]='"/account/profile/kyc"'>
                </scp-btn>
              </ng-template>
            </div>
            <div *ngSwitchDefault>
              <div class='scp-text'>
                <div class='scp-notification-error'></div>
                <div>
                  <span>{{ 'YOUR_SA_ID_IS_UNVERIFIED' | translate }}</span>
                  <ng-container
                    *ngIf='(customer.forbidden?.includes("kyc") || customer.forbidden?.includes("kyc-nr")) && customer.forbidden?.includes("ekyc")'>
                    <span>. Contact our support via livechat</span>
                  </ng-container>
                </div>
              </div>
              <ng-container
                *ngIf='(customer.forbidden?.includes("kyc") || customer.forbidden?.includes("kyc-nr")) && customer.forbidden?.includes("ekyc"); else verify'>
                <scp-btn
                  [hasMarginTop]='false'
                  text='Livechat'
                  height='36'
                  type='1'
                  width='73'
                  (click)='document.querySelector(".scp-header__chats")?.click()'>
                </scp-btn>
              </ng-container>
              <ng-template #verify>
                <scp-btn
                  [hasMarginTop]='false'
                  text='VERIFY'
                  height='36'
                  type='1'
                  width='73'
                  [routerLink]="'/account/profile/kyc'">
                </scp-btn>
              </ng-template>
            </div>
          </ng-container>
        </div>
        <div *ngIf='phoneRequired' class='scp-verify-phone'>
          <div>
            <div class='scp-text'>
              <div class='scp-notification-error'></div>
              {{ 'YOUR_PHONE_IS_UNVERIFIED' | translate }}
            </div>
            <scp-btn
              [hasMarginTop]='false'
              text='VERIFY'
              height='34'
              type='1'
              width='71'
              routerLink='/account/profile/phone/verify'
            ></scp-btn>
          </div>
        </div>
        <div class='scp-dashboard-content full-width' [class]='layoutClass' [class.has-seo]='seo'>
          <div class='scp-page' [class]='layoutClass' [class.authorized]='isAuthorized'>
            <scp-title [isHiddenTitle]='isVisibleSkeletonLoader'></scp-title>
            <scp-skeleton-loader *ngIf='isVisibleSkeletonLoader'></scp-skeleton-loader>
            <div class='w'
                 [class.not-full]="!layoutClass?.includes('full-width')"
                 [hidden]='isVisibleSkeletonLoader'
                 [style.display]="isVisibleSkeletonLoader ? 'none' : 'flex'">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
        <scp-seo class='scp-seo'
                 *ngIf='seo'
                 [seoText]='seo'
                 [loading]='isVisibleSkeletonLoader'
                 [class.authorized]='isAuthorized'>
        </scp-seo>
        <scp-footer *ngIf='hasFooter'></scp-footer>
      </div>
    </div>
    <button mat-mini-fab (click)='dashboardScrollingBlock.scrollTo({ top: 0, behavior: "smooth" })' class='scp-to-top'
            #top
            aria-label='Expand'>
      <img src='/assets/images/svg/scroll-to-top.svg' alt='' height='16' width='16' loading='lazy' />
    </button>
    <div [style.display]="isVisibleSkeletonLoader || !isFloatCartAllowed ? 'none' : 'block'">
      <scp-cart-button [float]='true' [visible]='isFloatCart && isFloatCartAllowed && !isVisibleSkeletonLoader'
                       class='cart-button'></scp-cart-button>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
