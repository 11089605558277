import { inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  firstValueFrom,
  Observable,
  of,
  ReplaySubject,
  Subject,
  switchMap,
  throwError,
} from 'rxjs';
import { Money } from '@scpc/dto';
import { EventOutcome, Event, EventMarket } from '@scpc/modules/sports/dto';
import {
  isSameSelectionId,
  SportSelectionsCollection,
} from '@scpc/modules/cart/services/sport-selections.collection';
import { catchError, finalize, map } from 'rxjs/operators';
import { SportCart, SportCartError, SportCartSelection, SportCartSelectionId } from '@scpc/modules/cart/model';
import { HttpClient } from '@angular/common/http';
import { formatMoney, toCoins } from '@scpc/utils/money.utils';
import { CookiesService } from '@scpc/modules/cookies/cookies.service';
import { LayoutService, StorageService } from '@scpc/modules/common/services';
import { environment } from '@scp-env/environment';
import { GoogleTagManagerService } from '@scpc/modules/common/services/google-tag-manager.service';

@Injectable({ providedIn: 'root' })
export class SportsCartService {

  private model: BehaviorSubject<any> = new BehaviorSubject(null);

  private readonly sportSelectionIdsCollection: SportSelectionsCollection = inject(SportSelectionsCollection);
  private internalSelectionIds: SportCartSelectionId[] = [];
  private internalCreatingSelectionIds: SportCartSelectionId[] = [];
  private internalDeletingSelectionIds: SportCartSelectionId[] = [];
  private readonly internalCreatingSelectionId$: Subject<SportCartSelectionId[]> = new BehaviorSubject([]);
  private readonly numberOfBets: Subject<number> = new BehaviorSubject<number>(0);

  private currency: string = inject(StorageService).getCurrency();

  private initialized = false;

  private $items: Subject<SportCartSelectionId[]> = new ReplaySubject(1);
  private $deletingItems: Subject<SportCartSelectionId[]> = new ReplaySubject(1);

  constructor(private readonly http: HttpClient,
              private readonly cookiesService: CookiesService,
              private readonly storageService: StorageService,
              private readonly googleTagManagerService: GoogleTagManagerService) {
    this.$items.subscribe((items: SportCartSelectionId[]): void => {
      this.internalSelectionIds = items;
      if (environment.isSportCart) {
        this.numberOfBets.next(this.internalSelectionIds.length);
      }
    });
    this.$deletingItems.subscribe((items: SportCartSelectionId[]): void => {
      this.internalDeletingSelectionIds = items;
    });
  }

  public initialize(): Observable<void> {
    if (!this.initialized && environment.isSportCart) {
      this.initialized = true;
      if (this.hasSportCartId()) {
        return this.sportSelectionIdsCollection.createMany({ request: this.getFromStorage() })
          .pipe(map(() => this.$items.next(this.sportSelectionIdsCollection.$items())));
      }
    }
    return of(void 0);
  }

  public initializeCart(): Observable<void> {
    this.model.next(null);
    if (this.getNumberOfBetsAsNumber() === 0) {
      this.model.next({
        selections: [],
        type: 'SINGLE',
        stakesConfig: { SINGLE: [], MULTI: [] },
        totalStakeAsMoney: { value: 0, currency: this.currency },
        potentialReturnAsMoney: { value: 0, currency: this.currency },
      });
      return of(void 0);
    } else {
      return this.http.get<SportCart>(`/br/api/cart/${this.getCartId()}`)
        .pipe(catchError(() => of({
          selections: [],
          type: 'SINGLE',
          stakesConfig: { SINGLE: [], MULTI: [] },
          totalStakeAsMoney: { value: 0, currency: this.currency },
          potentialReturnAsMoney: { value: 0, currency: this.currency },
        })), map((cart: SportCart) => {
          this.normalizeCart(cart);
        }));
    }
  }

  public getModelChanges(): Observable<SportCart> {
    return this.model.asObservable();
  }

  public getNumberOfBets(): Observable<number> {
    return this.numberOfBets;
  }

  public getNumberOfBetsAsNumber(): number {
    return environment.isSportCart ? this.internalSelectionIds.length :  /* istanbul ignore next */ 0;
  }

  public async addOrRemoveSelection(event: Event,
                                    market: EventMarket,
                                    marketOutcome: string | EventOutcome,
                                    source: string): Promise<void> {
    const outcome: EventOutcome = typeof marketOutcome === 'string'
      ? market.outcomes.find((o: EventOutcome): boolean => o.id === marketOutcome)
      : marketOutcome;
    const selectionId: SportCartSelectionId = {
      eventId: event.id,
      marketUniqId: market.marketUniqId,
      outcomeId: outcome.id,
      odds: outcome.odds,
    };
    if (this.hasSelection(selectionId)) {
      await firstValueFrom(this.removeSelection(selectionId));
      this.googleTagManagerService.removeFromSportCart(event, source);
    } else {
      this.internalCreatingSelectionIds.push(selectionId);
      this.internalCreatingSelectionId$.next(this.internalCreatingSelectionIds);
      await firstValueFrom(this.sportSelectionIdsCollection.create({ request: this.addToStorage(selectionId, source) }));
      this.googleTagManagerService.addToSportCart(event, source);
      this.internalCreatingSelectionIds = this.internalCreatingSelectionIds.filter((id: SportCartSelectionId): boolean => !isSameSelectionId(id, selectionId));
      this.internalCreatingSelectionId$.next(this.internalCreatingSelectionIds);
      this.updateState();
    }
  }

  public removeSelection(selectionId: SportCartSelectionId, full: boolean = false): Observable<void> {
    if (full) {
      return this.http.put(`/br/api/cart/${this.getCartId()}`, {
        action: 'DELETE_SELECTIONS',
        selectionsToDelete: [{ id: selectionId.id }],
        ...this.getNewStakes([selectionId]),
      }).pipe(
        map((cart: SportCart): void => {
          this.normalizeCart(cart);
        }),
        switchMap(() => {
          this.$deletingItems.next([...new Set([...this.sportSelectionIdsCollection.$deletingItems(), { id: selectionId.id } as SportCartSelectionId])]);
          return this.sportSelectionIdsCollection.delete({
            request: of(selectionId),
            item: selectionId,
          }).pipe(finalize((): void => this.updateState()), map(() => void 0));
        }));
    } else {
      this.$deletingItems.next([...new Set([...this.sportSelectionIdsCollection.$deletingItems(), selectionId])]);
      return this.sportSelectionIdsCollection.delete({
        request: this.removeFromStorage(selectionId),
        item: selectionId,
      }).pipe(finalize(() => this.updateState()), map(() => void 0));
    }
  }

  public items(): Observable<SportCartSelectionId[]> {
    return this.$items;
  }

  public creatingItems(): Observable<SportCartSelectionId[]> {
    return this.internalCreatingSelectionId$;
  }

  public deletingItems(): Observable<SportCartSelectionId[]> {
    return this.$deletingItems;
  }

  public hasSelection(selectionId: SportCartSelectionId): boolean {
    return this.hasSelectionId(this.internalSelectionIds, selectionId);
  }

  public isCreating(selectionId: SportCartSelectionId): boolean {
    return this.hasSelectionId(this.internalCreatingSelectionIds, selectionId);
  }

  public isDeleting(selectionId: SportCartSelectionId): boolean {
    return this.hasSelectionId(this.internalDeletingSelectionIds, selectionId);
  }

  public removeSelections(selectionIds: SportCartSelectionId[]): Observable<void> {
    return this.http.put(`/br/api/cart/${this.getCartId()}`, {
      action: 'DELETE_SELECTIONS',
      selectionsToDelete: selectionIds.map((selectionId: SportCartSelectionId) => ({ id: selectionId.id })),
      ...this.getNewStakes(selectionIds),
    }).pipe(switchMap((cart: SportCart) => {
      this.normalizeCart(cart);
      this.$deletingItems.next([...new Set([...this.sportSelectionIdsCollection.$deletingItems(), ...selectionIds])]);
      return this.sportSelectionIdsCollection.deleteMany({
        request: of(true),
        items: selectionIds,
      });
    }), map(() => this.$items.next(this.sportSelectionIdsCollection.$items())));
  }

  public updateType(type: 'SINGLE' | 'MULTI'): Observable<void> {
    return this.runAction('SWITCH_BETSLIP_MODE', { betslipMode: type });
  }

  public updateSource(source: string): Observable<void> {
    return this.runAction('UPDATE_SOURCE', { source });
  }

  public acceptAllChanges(): Observable<void> {
    return this.runAction('APPLY_ODDS_CHANGES');
  }

  public updateAllStakes(toSkip: SportCartSelectionId[] = []): Observable<void> {
    const cart: SportCart = this.model.value;
    if (cart) {
      if (cart.type === 'SINGLE') {
        const selectionsToUpdate = this.getSelectionsToUpdate(toSkip);
        if (selectionsToUpdate.length > 0) {
          return this.http.put(`/br/api/cart/${this.getCartId()}`, {
            action: 'SET_STAKES',
            selectionsToUpdate,
            // eslint-disable-next-line @typescript-eslint/no-shadow
          }).pipe(map((cart: SportCart): void => {
            this.normalizeCart(cart);
          }));
        }
      } else {
        if (toCoins(cart.totalStake.toString(), cart.totalStakeAsMoney.currency) !== cart.totalStakeAsMoney.value) {
          cart.totalStake = Number(formatMoney(cart.totalStakeAsMoney, false, true).replace(/,/g, ''));
          return this.http.put(`/br/api/cart/${this.getCartId()}`, {
            action: 'SET_STAKES',
            totalStake: cart.totalStake > 0 ? cart.totalStake : null,
            // eslint-disable-next-line @typescript-eslint/no-shadow
          }).pipe(map((cart: SportCart) => {
            this.normalizeCart(cart);
          }));
        }
      }
    }
    return of(void 0);
  }

  public acceptOddsChanges(): Observable<void> {
    return this.runAction('SWITCH_APPLY_CHANGES_FLAG');
  }

  public placeBets(): Observable<void> {
    return this.runAction('REGISTER_BETS');
  }

  public async clearAll(): Promise<void> {
    this.$deletingItems.next([...new Set([...this.sportSelectionIdsCollection.$deletingItems(), ...this.internalSelectionIds])]);
    await firstValueFrom(this.sportSelectionIdsCollection.deleteMany({
      request: this.clearStorage(),
      items: this.internalSelectionIds,
    }).pipe(finalize((): void => this.updateState())));
  }

  private runAction(action: string, data: object = {}): Observable<void> {
    return this.http.put(`/br/api/cart/${this.getCartId()}`, {
      action,
      ...data,
      ...this.getNewStakes(),
    }, {
      headers: {
        'x-device-info': this.storageService.device || LayoutService.getDevice(''),
      },
    }).pipe(map((cart: SportCart): void => {
      cart = this.normalizeCart(cart);
      if ('REGISTER_BETS' === action) {
        this.googleTagManagerService.purchaseSportsWagers(cart);
      }
    }));
  }

  private hasSelectionId(selections: SportCartSelectionId[], selectionId: SportCartSelectionId): boolean {
    return !!selections.find((id: SportCartSelectionId) => isSameSelectionId(selectionId, id));
  }

  private getFromStorage(): Observable<SportCartSelectionId[]> {
    return this.http.get<{ selections: SportCartSelectionId[] }>(`/br/api/shortcart/${this.getCartId()}`)
      .pipe(map((response: { selections: SportCartSelectionId[] }) => response.selections), catchError(() => of([])));
  }

  private addToStorage(sportSelectionId: SportCartSelectionId, source: string): Observable<SportCartSelectionId> {
    return this.http.put(`/br/api/cart/${this.getCartId()}/selections`, {
      eventId: sportSelectionId.eventId,
      marketUniqId: sportSelectionId.marketUniqId,
      outcomeId: sportSelectionId.outcomeId,
      odds: sportSelectionId.odds,
      source,
    }).pipe(switchMap((response: { error?: SportCartError }) => {
      /* istanbul ignore if */
      if (response.error) {
        return throwError(() => response.error);
      }
      return of(sportSelectionId);
    }));
  }

  private removeFromStorage(sportSelectionId: SportCartSelectionId): Observable<SportCartSelectionId> {
    const path: string = `/br/api/cart/${this.getCartId()}/selections/${sportSelectionId.eventId}${sportSelectionId.marketUniqId}${sportSelectionId.outcomeId}`;
    return this.http.delete(path).pipe(map(() => sportSelectionId));
  }

  private clearStorage(): Observable<any> {
    return this.http.delete(`/br/api/cart/${this.getCartId()}`);
  }

  /* istanbul ignore next */
  private getCartId(): string {
    let id: string = this.cookiesService.get('sportCartId');
    if (!id) {
      id = this.generateID();
      const now: Date = new Date();
      now.setFullYear(now.getFullYear() + 2);
      this.cookiesService.put('sportCartId', id, {
        expires: now,
      });
    }
    return id;
  }

  private hasSportCartId(): boolean {
    return !!this.cookiesService.get('sportCartId');
  }

  private normalizeCart(cart: SportCart): SportCart {
    if (!cart.type) {
      cart.type = 'SINGLE';
    }
    if (!cart.stakesConfig) {
      cart.stakesConfig = { SINGLE: [], MULTI: [] };
    }

    if (!cart.totalStakeAsMoney) {
      cart.totalStakeAsMoney = { value: 0, currency: this.currency };
      cart.potentialReturnAsMoney = { value: 0, currency: this.currency };
    }

    /* istanbul ignore if */
    if (!cart.selections) {
      cart.selections = [];
    } else {
      cart.selections.forEach((selection: SportCartSelection): void => {
        selection.internalId = selection.id.replace(/:/g, '');
        selection.stakeAsMoney = this.toMoney(selection.stake);
      });
      cart.totalStakeAsMoney = this.toMoney(cart.totalStake);
      cart.potentialReturnAsMoney = this.toMoney(cart.potentialReturn);
    }
    this.model.next(cart);
    return cart;
  }

  private toMoney(value: number): Money {
    return {
      value: value ? toCoins(value.toString(), this.currency) : null,
      currency: this.currency,
    };
  }

  private getNewStakes(toSkip: SportCartSelectionId[] = []) {
    return {
      totalStake: this.getTotalStake(),
      selectionsToUpdate: this.getSelectionsToUpdate(toSkip),
    };
  }

  private getSelectionsToUpdate(toSkip: SportCartSelectionId[]): { id: string, stake: number | null }[] {
    const ids = toSkip.map((selectionId: SportCartSelectionId) => selectionId.id);
    return this.model
      .value
      .selections
      .filter((selection: SportCartSelection) => !ids.includes(selection.id) &&
        selection.stakeAsMoney.value !== toCoins((selection.stake ||  /* istanbul ignore next */ '').toString(), selection.stakeAsMoney.currency))
      .map(selection => ({ id: selection.id, stake: this.moneyToNumber(selection.stakeAsMoney) }));
  }

  private getTotalStake(): number {
    return this.moneyToNumber(this.model.value.totalStakeAsMoney);
  }

  private moneyToNumber(money: Money): number | null {
    return money.value > 0
      ? Number(formatMoney(money, false, true).replace(/,/g, ''))
      : null;
  }

  /* istanbul ignore next */
  private generateID = () => {
    let d = new Date().getTime();
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      let r = Math.random() * 16;
      if (d > 0) {
        // eslint-disable-next-line no-bitwise
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        // eslint-disable-next-line no-bitwise
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      // eslint-disable-next-line no-bitwise
      return (c === 'x' ? r : (r & 0x7 | 0x8)).toString(16);
    });
  };

  private updateState(): void {
    this.$items.next(this.sportSelectionIdsCollection.$items());
    this.$deletingItems.next(this.sportSelectionIdsCollection.$deletingItems());
  }

}
