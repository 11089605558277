import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { RouteTransformerDirective } from '@scpc/modules/common/directives/route.transformer.directive';

@Component({
  selector: 'scp-seo',
  templateUrl: './seo.component.html',
  styleUrls: ['./seo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    RouteTransformerDirective
  ]
})
export class SeoComponent {

  @Input()
  public loading = false;

  protected seo: SafeHtml;

  constructor(private readonly domSanitizer: DomSanitizer) {
  }

  @Input()
  public set seoText(text: string | null) {
    this.seo = text ? this.domSanitizer.bypassSecurityTrustHtml(text) : null;
  }

  /* istanbul ignore next */
  protected updateSeoState(e: HTMLDivElement) {
    const seoE: HTMLDivElement = e.getElementsByClassName('seo')[0] as HTMLDivElement;
    if (e.classList.toggle('expanded')) {
      seoE.style.maxHeight = seoE.scrollHeight + 'px';
    } else {
      seoE.style.maxHeight = '195px';
    }
  }

}
