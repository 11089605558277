<div class="scp-footer">
  <ul class="scp-footer__list">
    <li *ngFor="let item of items; let last = last; trackBy:trackByFn">
      <a [routerLink]="item.route" routerLinkActive="active" *ngIf="!item.external">{{ item.title | translate }}</a>
      <a [href]="item.route" target="_blank" *ngIf="item.external"
         rel="nofollow noopener">{{ item.title | translate }}</a>
      <span *ngIf="!last">|</span>
    </li>
  </ul>
  <div class="footer" [innerHtml]="content" *ngIf="content" [class.hide-apps]="isNativeApp"></div>
  <scp-clock></scp-clock>
</div>
