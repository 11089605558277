import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TourService } from 'ngx-ui-tour-core';
import { TourAnchorTippyDirective } from './tippy-tour-anchor.directive';
import { TourStepTemplateComponent } from './tippy-tour-step-template.component';
import { TourStepTippyTemplateService } from './tippy-tour-step-template.service';
import { TourTippyService } from '@scpc/modules/common/tour/tippy-tour.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatRippleModule } from '@angular/material/core';
import { popperVariation, provideTippyConfig } from '@ngneat/helipopper';
import { sticky } from 'tippy.js';

export {
  TourAnchorTippyDirective,
  TourStepTemplateComponent,
  TourTippyService
};

@NgModule({
  declarations: [
    TourAnchorTippyDirective,
    TourStepTemplateComponent
  ],
  exports: [
    TourAnchorTippyDirective,
    TourStepTemplateComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatRippleModule
  ],
  providers: [
    provideTippyConfig({
      defaultVariation: 'popover',
      variations: {
        popover: popperVariation,
        'popover-info': {
          arrow: true,
          maxWidth: 290,
          duration: 500,
          animation: 'scale',
          theme: 'light-border',
          interactive: true,
          inertia: true,
          sticky: true,
          offset: [0, 0],
          plugins: [sticky]
        }
      }
    })
  ]
})
export class TourTippyModule {
  public static forRoot(): ModuleWithProviders<TourTippyModule> {
    return {
      ngModule: TourTippyModule,
      providers: [
        TourStepTippyTemplateService,
        TourService,
        TourTippyService
      ]
    };
  }
}
