import { Directive } from '@angular/core';
import { Dialog, DialogConfig, DialogRef } from '@angular/cdk/dialog';

@Directive()
export abstract class AsyncDialog<ComponentType, DataType, ReturnType = unknown> {

  public constructor(protected dialog: Dialog) {
  }

  protected defaultOptions(data: { isAuthorized: boolean }): DialogConfig {
    return {
      autoFocus: false,
      panelClass: ['scp-filter-with-border', 'scp-filter', !data.isAuthorized ? 'scp-filter-without-margin' : ''],
      backdropClass: 'scp-backdrop',
      maxHeight: 'calc(100% - 20px)'
    };
  }

  abstract open(data: DataType): Promise<DialogRef<ReturnType, ComponentType>>;

}
