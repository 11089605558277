import { ChangeDetectionStrategy, Component, HostListener, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';
import { RouteTransformerDirective } from '@scpc/modules/common/directives/route.transformer.directive';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'scp-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, MatSnackBarModule, MatButtonModule, RouteTransformerDirective]
})
export class NotificationComponent {

  private count = 0;

  constructor(
    protected readonly sanitizer: DomSanitizer,
    private readonly snackBarRef: MatSnackBarRef<NotificationComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public readonly data: { type: string; message: string; messageParams?: any }
  ) {
  }

  @HostListener('window:click', ['$event'])
  public closeOnAnyClick(event: MouseEvent) {
    const e = event.target as HTMLElement;
    if (this.count === 0 && (!e.classList || /* istanbul ignore next */ !e.classList.contains('link'))) {
      this.count++;
      return;
    }
    event.stopPropagation();
    event.preventDefault();
    this.snackBarRef.dismiss();
  }

  public close(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.snackBarRef.dismiss();
  }

}
