import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from '@scpc/app.component';
import { config } from '@scpc/app.config';
import { mergeApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { getHost } from '@scpc/utils/host.utils';

bootstrapApplication(AppComponent, mergeApplicationConfig(config, {
  providers: [
    provideZoneChangeDetection({ runCoalescing: true, eventCoalescing: true }),
    { provide: 'HOST', useValue: getHost(window) },
  ],
})).catch((err) => console.error(err));
