import { StorageService } from '@scpc/modules/common/services/storage.service';
import { ConfigService } from '@scpc/modules/common/services/config.service';
import { AuthenticationService } from '@scpc/modules/common/services/authentication.service';
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ScpWebSocketService {

  private socket: any | null = null;
  private subscriptions = new Map();
  private topics = new Map<string, Subject<any>>();
  private connecting = false;

  constructor(
    private readonly storageService: StorageService,
    private readonly authenticationService: AuthenticationService,
    private readonly configService: ConfigService,
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private readonly zone: NgZone,
  ) {
    if (isPlatformBrowser(this.platformId) && this.storageService.accessToken) {
      this.connect();
    }
    this.authenticationService.authorization.subscribe((value) => {
      if (value) {
        this.connect(true);
      } else if (this.socket) {
        this.socket.emit('sign-out');
      }
    });
  }

  public disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
      this.subscriptions.clear();
    }
    this.connecting = false;
  }

  public on(topic: string): Observable<any> {
    this.connect();
    /* istanbul ignore else */
    if (this.socket) {
      return new Observable((observer) => {
        this.socket.on(topic, (value: any) => {
          observer.next(value);
        });
      });
    } else {
      const o = new Subject();
      this.topics.set(topic, o);
      return o;
    }
  }

  public off(topic: string): void {
    if (this.socket) {
      this.socket.off(topic);
    }
  }

  public subscribe(topic: string): void {
    this.connect();
    this.subscriptions.set(topic, topic);
    /* istanbul ignore next */
    if (this.socket?.connected) {
      this.socket.emit('subscribe', topic);
    }
  }

  public unsubscribe(topic: string): void {
    if (this.socket) {
      this.subscriptions.delete(topic);
      try {
        this.socket.emit('unsubscribe', topic);
      } catch (_) {
      }
    }
  }

  private connect(signIn: boolean = false): void {
    /* istanbul ignore else */
    if (!this.connecting && !this.socket && isPlatformBrowser(this.platformId)) {
      this.connecting = true;
      this.configService.initialize.pipe(filter((value: boolean) => value), take(1)).subscribe(async () => {
        await this.zone.runOutsideAngular(async () => {
          this.socket = ((window as any).io || /* istanbul ignore next */ (await import('socket.io-client')).io)(window.location.origin, {
            path: '/websockets/api/1.0',
            transports: ['websocket', 'polling'],
            query: { siteId: this.configService.siteId, operationId: this.configService.operationId },
          });
          this.socket.on('connect', () => {
            this.connecting = false;
            if (this.storageService.accessToken) {
              this.socket.emit('sign-in', this.storageService.accessToken);
            }
            if (this.subscriptions.size) {
              for (const topic of this.subscriptions.values()) {
                this.socket.emit('subscribe', topic);
              }
            }
            /* istanbul ignore next */
            for (const entry of this.topics) {
              this.socket.on(entry[0], (value: any): void => entry[1].next(value));
            }
          });
        });
      });
    } else if (signIn) {
      this.socket.emit('sign-in', this.storageService.accessToken);
    }
  }

}
