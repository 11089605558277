import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { AuthenticationService, LayoutService } from '@scpc/modules/common/services';
import { firstValueFrom, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { LuckyNumbersCartService } from '@scpc/modules/cart/services/lucky-numbers-cart.service';
import { ActivatedRoute, NavigationStart, Params, Router, RouterModule } from '@angular/router';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { PromotionService } from '@scpc/modules/promotions/services/promotion.service';
import {
  LuckyNumbersCartComponent,
} from '@scpc/modules/cart/components/lucky-numbers-cart/lucky-numbers-cart.component';
import { CartDialogService } from '@scpc/modules/cart/services/cart.dialog.service';
import { ScpTourService } from '@scpc/modules/common/services/scp.tour.service';
import { EmailBonusDialogService } from '@scpc/modules/email-bonus/services/email-bonus.dialog.service';
import { EmailBonusComponent } from '@scpc/modules/email-bonus/components/email-bonus/email-bonus.component';
import { Bonus } from '@scpc/dto';
import { animate, style, transition, trigger } from '@angular/animations';
import { TranslateModule } from '@ngx-translate/core';
import { TourTippyModule } from '@scpc/modules/common/tour/tippy-tour.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SportCartComponent } from '@scpc/modules/cart/components/sport-cart/sport-cart.component';
import { MatButtonModule } from '@angular/material/button';
import { SportsCartService } from '@scpc/modules/cart/services/sports-cart.service';

@Component({
  selector: 'scp-cart-button',
  templateUrl: './cart-button.component.html',
  styleUrls: ['./cart-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('valueAnimation', [
      transition(':increment', [
          style({ transform: 'scale(0.5)', opacity: '0.5' }),
          animate('150ms ease-out', style({ transform: 'scale(1.4)', opacity: 1 })),
          animate('150ms ease-out', style({ transform: 'scale(1)' })),
        ],
      ),
      transition(':decrement', [
          style({ transform: 'scale(0.5)', opacity: 0.5 }),
          animate('150ms ease-out', style({ transform: 'scale(1.4)', opacity: 1 })),
          animate('150ms ease-out', style({ transform: 'scale(1)' })),
        ],
      ),
    ]),
  ],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    TourTippyModule,
    DragDropModule,
    MatButtonModule,
  ],
})
export class CartButtonComponent implements OnInit, OnDestroy {

  @Input()
  public float = false;

  public numberOfLuckyNumbersBets = -1;
  public numberOfSportBets = -1;
  public cart = false;
  public cartDialogRef: DialogRef<{
    emailBonus?: Bonus,
    numberOfBets?: number,
    review?: boolean
  }, LuckyNumbersCartComponent | SportCartComponent>;
  public emailDialogRef: DialogRef<void, EmailBonusComponent>;
  public isVisible = false;

  private readonly destroy: Subject<void> = new Subject<void>();
  private dragging = false;

  constructor(private readonly luckyNumbersCartService: LuckyNumbersCartService,
              private readonly sportsCartService: SportsCartService,
              private readonly authenticationService: AuthenticationService,
              private readonly changeDetectorRef: ChangeDetectorRef,
              private readonly activatedRoute: ActivatedRoute,
              private readonly promotionService: PromotionService,
              private readonly cartDialogService: CartDialogService,
              private readonly emailBonusDialogService: EmailBonusDialogService,
              private readonly elementRef: ElementRef,
              private readonly router: Router,
              private readonly scpTourService: ScpTourService,
              private readonly zone: NgZone,
              private readonly layoutService: LayoutService,
              @Inject(PLATFORM_ID) public readonly platformId: string) {
  }

  @Input()
  public set visible(visible: boolean) {
    this.isVisible = visible;
    this.updateTour();
    this.changeDetectorRef.markForCheck();
  }

  public async ngOnInit() {
    const isBrowser: boolean = isPlatformBrowser(this.platformId);
    this.luckyNumbersCartService.getNumberOfBets()
      .pipe(takeUntil(this.destroy))
      .subscribe((numberOfBets: number) => {
        this.numberOfLuckyNumbersBets = numberOfBets;
        this.updateState();
      });
    this.sportsCartService.getNumberOfBets()
      .pipe(takeUntil(this.destroy))
      .subscribe((numberOfBets: number) => {
        this.numberOfSportBets = numberOfBets;
        this.updateState();
      });

    if (isBrowser) {
      if (this.activatedRoute.snapshot.queryParams.promotion) {
        this.cart = this.promotionService.hasPromotion;
        this.promotionService.showErrorsIfRequired();
      }
      if (!this.float) {
        this.activatedRoute.queryParams.pipe(takeUntil(this.destroy)).subscribe(async (params: Params) => {
          if (params.promotion) {
            this.promotionService.showErrorsIfRequired();
            if (this.promotionService.hasPromotion) {
              await this.showCart();
            }
          }
        });
      }
      this.router.events
        .pipe(takeUntil(this.destroy))
        .pipe(filter((event: NavigationStart): boolean => event instanceof NavigationStart), filter(() => !!this.cartDialogRef))
        .subscribe(() => {
          this.cartDialogRef.close();
        });
    }
  }

  public ngOnDestroy() {
    this.cartDialogRef?.close();
    this.emailDialogRef?.close();
    this.destroy.next();
    this.destroy.complete();
  }

  public async showCart(): Promise<void> {
    /* istanbul ignore if */
    if (this.dragging) {
      this.started(false);
      return;
    }
    this.updateTour();
    const isAuthorized: boolean = await firstValueFrom(this.authenticationService.isAuthorized());
    this.cartDialogRef = await this.cartDialogService.open({
      isAuthorized,
      product: this.numberOfLuckyNumbersBets > 0 ? 'LUCKY_NUMBERS' : 'SPORT',
    });
    this.cartDialogRef
      .closed
      .pipe(take(1), takeUntil(this.destroy))
      .subscribe(async (data: { emailBonus?: Bonus, numberOfBets?: number, review?: boolean }) => {
        if (data?.numberOfBets) {
          if (data.emailBonus) {
            this.emailDialogRef = await this.emailBonusDialogService.open({
              isAuthorized,
              emailBonus: data.emailBonus,
            });
            this.emailDialogRef.closed.pipe(take(1), takeUntil(this.destroy)).subscribe(() => this.updateTour());
          } else {
            this.updateTour();
          }
          this.layoutService.showNotification(data.numberOfBets > 1 ? 'BETS_HAVE_BEEN_REGISTERED' : 'BET_HAS_BEEN_REGISTERED');
        }
      });
  }

  /* istanbul ignore next */
  public started(started: boolean) {
    this.dragging = started;
    this.updateTour();
  }

  private updateState(): void {
    this.changeDetectorRef.markForCheck();
    if (this.numberOfSportBets <= 0 && this.numberOfLuckyNumbersBets <= 0) {
      this.elementRef.nativeElement.classList.remove('empty');
      this.elementRef.nativeElement.classList.add('empty');
    } else {
      this.elementRef.nativeElement.classList.remove('empty');
      this.updateTour();
    }
  }

  private updateTour() {
    if (this.float) {
      if (this.isVisible && this.numberOfLuckyNumbersBets > 0 && !this.dragging) {
        this.zone.run(() => this.scpTourService.showCartTour());
      } else {
        this.zone.run(() => this.scpTourService.hideCartTour());
      }
    }
  }

}
