import { inject, Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { NotificationComponent } from '../components/notification/notification.component';
import { StorageService } from './storage.service';
import { PageData } from '@scpc/modules/common/resolvers/page.resolver';
import { isPlatformServer } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class LayoutService {

  private snackBarRef: MatSnackBarRef<NotificationComponent> | null = null;
  private title: Subject<string | null> = new BehaviorSubject<string | null>(null);
  private back: Subject<string | null> = new BehaviorSubject<string | null>(null);
  private seo: Subject<PageData | null> = new BehaviorSubject<PageData | null>(null);
  private desktop: boolean | null = null;
  private tablet: boolean | null = null;
  private mobile: boolean | null = null;
  private server: boolean = isPlatformServer(inject(PLATFORM_ID));

  constructor(
    private readonly storageService: StorageService,
    private readonly snackbar: MatSnackBar,
    @Optional() @Inject('USER_AGENT') private readonly userAgent: string,
  ) {
  }

  public static getDevice(userAgent: string): string {
    const ua: string = userAgent || navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    }
    if (
      /(Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini))/i.test(
        ua,
      )
    ) {
      return 'mobile';
    }
    return 'desktop';
  }

  private static getErrorMessage(error: HttpErrorResponse, defaultMessage: string): string {
    if (error.status === 0 && error.error instanceof ProgressEvent) {
      return 'ERROR.NO_INTERNET';
    }
    switch (error.error?.code) {
      case 2000:
        return 'ERROR.INVALID_SA_ID';
      case 4008:
        return 'ERROR.INVALID_PASSWORD';
      case 4011:
        return 'ERROR.ACCOUNT_HAS_BEEN_BLOCKED';
      case 7003:
        return 'ERROR.CAN_NOT_WITHDRAW_MONEY_FROM_THE_CARD';
      case 7011:
        return 'ERROR.INVALID_EXPIRY_DATE';
      case 7012:
        return 'ERROR.CAN_NOT_USE_ONE_VOUCHER_AT_THIS_MOMENT';
      case 7013:
        return 'ERROR.VOUCHER_NOT_FOUND';
      case 7014:
        return 'ERROR.VOUCHER_ALREADY_USED';
      case 7020:
        return 'ERROR.VOUCHER_SPOILT';
      case 7021:
        return 'ERROR.VOUCHER_HOLD';
      case 7022:
        return 'ERROR.VOUCHER_ALREADY_EXPIRED';
      case 20003:
        return 'ERROR.EMPTY_PHONE_NUMBER';
      case 20004:
        return 'ERROR.INVALID_PHONE_NUMBER';
      case 7015:
        return 'ERROR.CAN_NOT_USE_OTT_VOUCHER_AT_THIS_MOMENT';
      case 12009:
        return 'ERROR.WALLET_ACCOUNT_IS_NOT_ACTIVE';
      case 12013:
        return 'ERROR.CAN_NOT_ACCEPT_BONUS_PROGRAM_OFFER';
      case 12014:
        return 'ERROR.CAN_NOT_REJECT_BONUS_PROGRAM_OFFER';
      case 14002:
        return 'ERROR.CAN_NOT_LOAD_CREDIT_TOKEN';
      case 20001:
        return 'ERROR.EMPTY_SA_ID';
      case 20012:
        return 'ERROR.EMPTY_AMOUNT';
      case 20013:
        return 'ERROR.EMPTY_CARDHOLDER';
      case 20014:
        return 'ERROR.EMPTY_CARD_NUMBER';
      case 20015:
        return 'ERROR.INVALID_CARD_NUMBER';
      case 20016:
        return 'ERROR.EMPTY_EXPIRY_YEAR';
      case 20017:
        return 'ERROR.EMPTY_EXPIRY_MONTH';
      case 20018:
        return 'ERROR.EMPTY_CVV_NUMBER';
      case 20020:
        return 'ERROR.CARD_NOT_ALLOWED';
      case 20019:
        return 'ERROR.INVALID_CVV_NUMBER';
      case 12021:
        return 'ERROR.NOT_ENOUGH_MONEY';
      case 20007:
        return 'ERROR.CANNOT_USE_YOUR_OWN_NUMBER';
      case 4012:
      case 20022:
      case 20048:
        return error.error.message;
      case 20031:
        return 'ERROR.INVALID_OTP';
      case 20036:
      case 20037:
      case 20043:
      case 20045:
        return 'ERROR.EMPTY_PIN';
      case 20038:
      case 20039:
      case 20044:
      case 20046:
        return 'ERROR.INVALID_PIN';
      case 20047:
        return 'ERROR.OTP_EXPIRED';
      case 20011:
        return 'ERROR.AMOUNT_ROUND_NUMBERS';
      case 13011:
        return 'ERROR.CAN_NOT_LOAD_BANK';
      case 15003:
        return 'ERROR.DRAW_HAS_BEEN_STARTED';
      case 15005:
        return 'ERROR.DRAW_HAS_BEEN_CANCELLED';
      case 15006:
        return 'ERROR.EXCEEDED_MAX_STAKE';
      case 15007:
        return 'ERROR.EXCEEDED_MAX_PAYOUT';
      case 15008:
        return 'ERROR.DRAW_HAS_BEEN_STARTED';
      case 13022:
        return 'ERROR.EMAIL_ALREADY_EXISTS';
      case 20052:
        return 'ERROR.INCORRECT_BANK_DETAILS';
      case 20053:
        return 'ERROR.CAN_NO_VERIFY_BAN_ACCOUNT';
      default:
        return defaultMessage || error.error?.message || 'ERROR.UE';
    }
  }

  public showNotification(message: string, messageParams?: any): void {
    /* istanbul ignore if */
    if (this.server) {
      return;
    }
    const classes: string[] = ['scp-snackbar'];
    if (this.storageService.isAuthorised()) {
      classes.push('scp-authorized');
    }
    this.snackBarRef = this.snackbar.openFromComponent(NotificationComponent, {
      data: { message, messageParams, type: 'SUCCESS' },
      verticalPosition: 'top',
      panelClass: classes,
      duration: 10000,
    });
  }

  public showHttpErrorResponse(error: HttpErrorResponse, defaultMessage: string): void {
    this.showErrorNotification(LayoutService.getErrorMessage(error, defaultMessage));
  }

  public showErrorNotification(message: string, messageParams?: object): void {
    /* istanbul ignore if */
    if (this.server) {
      return;
    }
    const classes: string[] = ['scp-snackbar'];
    if (this.storageService.isAuthorised()) {
      classes.push('scp-authorized');
    }
    this.hideErrorNotification();
    this.snackBarRef = this.snackbar.openFromComponent(NotificationComponent, {
      data: { message: message || 'ERROR', messageParams, type: 'ERROR' },
      verticalPosition: 'top',
      panelClass: classes,
      duration: 10000,
    });
  }

  public hideErrorNotification() {
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
    }
  }

  public isDesktop(): boolean {
    if (this.desktop === null) {
      this.desktop = LayoutService.getDevice(this.userAgent) === 'desktop';
    }
    return this.desktop;
  }

  public isTablet(): boolean {
    if (this.tablet === null) {
      this.tablet = LayoutService.getDevice(this.userAgent) === 'tablet';
    }
    return this.tablet;
  }

  public isMobile(): boolean {
    if (this.mobile === null) {
      this.mobile = LayoutService.getDevice(this.userAgent) === 'mobile';
    }
    return this.mobile;
  }

  public getTitle(): Observable<string | null> {
    return this.title;
  }

  public getBack(): Observable<string | null> {
    return this.back;
  }

  public getPageData(): Observable<PageData | null> {
    return this.seo;
  }

  public setPageData(seo: PageData | null): void {
    this.seo.next(null);
    this.seo.next(seo);
  }

  public setTitle(title: string): void {
    this.back.next(null);
    this.title.next(title);
  }

  public setTitleAndBack(title: string, back: string | null): void {
    this.back.next(back);
    this.title.next(title);
  }
}
