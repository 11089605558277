<div class='scp-cart-button'
     [tourAnchor]="isVisible && (!float || numberOfLuckyNumbersBets > 0) ? 'ln.step-15': null"
     [class.scp-cart-button__float]='float'
     cdkDrag
     cdkDragBoundary='mat-sidenav-container'
     (cdkDragStarted)='started(true)'
     (cdkDragReleased)='started(false)'
     [cdkDragDisabled]='!float'>
  <button class='scp-navbar__cart__logo'
          mat-icon-button
          (click)='showCart()'
          [attr.aria-label]="'LN.BETSLIP' | translate">
    <img src='/assets/images/svg/cart.svg' alt='' loading='lazy' />
  </button>
  <div class='scp-cart-button__number' [class.empty]='!numberOfLuckyNumbersBets && !numberOfSportBets'
       [@valueAnimation]='numberOfLuckyNumbersBets || numberOfSportBets'>
    <span
      *ngIf='numberOfLuckyNumbersBets || numberOfSportBets'> {{numberOfLuckyNumbersBets || numberOfSportBets}}</span>
  </div>
</div>
