import { formatCurrency, getCurrencySymbol, getNumberOfCurrencyDigits } from '@angular/common';
import { Money } from '@scpc/dto';

export function toCoins(amount: string, currency: string): number {
  return Number(amount.replace(/,/g, '')) * getNumberOfDigits(currency);
}

export function formatMoney(value: Money, symbol: boolean = true, coins: boolean = true): string {
  const d: number = getNumberOfDigits(value.currency);
  return formatCurrency(
    value.value / d,
    'en',
    symbol ? getCurrencySymbol(value.currency, 'narrow') : '',
    value.currency,
    coins || !Number.isInteger(value.value / d) ? undefined : '1.0-0',
  );
}

export function formatMoneyForExternalService(value: Money): string {
  const digits: number = getNumberOfDigits(value.currency);
  return (value.value / digits).toFixed(getNumberOfCurrencyDigits(value.currency));
}

function getNumberOfDigits(currency: string): number {
  return Math.pow(10, getNumberOfCurrencyDigits(currency));
}
