import { ApplicationRef, Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { SwUpdate, VersionEvent, VersionReadyEvent } from '@angular/service-worker';
import { interval, merge, Observable, of, ReplaySubject, Subject, switchMap } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { delay, filter, first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CheckForUpdateService {

  private availableSubject: Subject<VersionReadyEvent | null> = new ReplaySubject<VersionReadyEvent>(1);

  constructor(private readonly updates: SwUpdate,
              @Inject(PLATFORM_ID)
                platformId: string,
              zone: NgZone,
              appRef: ApplicationRef) {
    if (isPlatformBrowser(platformId) && updates.isEnabled) {
      const appIsStable$: Observable<boolean> = appRef.isStable.pipe(first((isStable: boolean): boolean => isStable === true));
      zone.runOutsideAngular((): void => {
        merge(appIsStable$, of(true).pipe(delay(30 * 1_000)))
          .pipe(first(), switchMap(() => interval(300 * 1_000)))
          .subscribe(() => updates.checkForUpdate());
      });

      this.updates.versionUpdates
        .pipe(filter((event: VersionEvent): event is VersionReadyEvent => event.type === 'VERSION_READY'))
        .subscribe((event: VersionReadyEvent): void => {
          console.log('current version is', event.currentVersion.hash);
          console.log('available version is', event.latestVersion.hash);
          this.availableSubject.next(event);
        });
    }
  }

  public get available(): Observable<VersionReadyEvent | null> {
    return this.availableSubject.asObservable();
  }

}
