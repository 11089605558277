<div class='scp-skeleton-loader'>
  <ng-container *ngIf='isMobile; else desktop'>
    <content-loader viewBox='0 0 515 500' backgroundColor='rgba(220, 229, 234, 0.5)' [baseUrl]='baseUrl'>
      <svg:rect x='0' y='0' rx='3' ry='3' width='55' height='55' />
      <svg:rect x='65' y='0' rx='3' ry='3' width='450' height='55' />
      <svg:rect x='0' y='65' rx='3' ry='3' width='515' height='70' />
      <svg:rect x='0' y='145' rx='3' ry='3' width='515' height='400' />
    </content-loader>
  </ng-container>
  <ng-template #desktop>
    <content-loader viewBox='0 0 515 500' backgroundColor='rgba(220, 229, 234, 0.5)' [baseUrl]='baseUrl'>
      <svg:rect x='0' y='0' rx='3' ry='3' width='35' height='35' />
      <svg:rect x='45' y='0' rx='3' ry='3' width='470' height='35' />
      <svg:rect x='0' y='45' rx='3' ry='3' width='515' height='50' />
      <svg:rect x='0' y='105' rx='3' ry='3' width='515' height='400' />
    </content-loader>
  </ng-template>
</div>
