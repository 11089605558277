<div class="scp-divider"></div>
<a
  mat-list-item
  class="scp-navigation-category-item"
  [routerLink]="url"
  [queryParams]="queryParams"
  [routerLinkActive]="'active-sub-item'"
>
  <div class="scp-block">
    <div>
      {{ title | translate }}
      <span *ngIf="count > 0" class="badge">{{count}}</span>
    </div>
    <img *ngIf="icon" src="/assets/images/svg/{{ icon }}.svg" alt="" [height]="iconSize?.height || 16" [width]="iconSize?.width || 16" loading="lazy" />
  </div>
</a>
