<div class='scp-products {{type}}'>
  <div class='scp-products__arrow scp-products__arrow__left' *ngIf='!reachBeginning'>
    <button
      mat-icon-button
      (click)='swiper.slidePrev()'
      [attr.aria-label]="'BUTTON.PREV_PAGE' | translate">
      <img src='/assets/images/svg/arrow-2.svg' width='24' height='24' alt=''>
    </button>
  </div>
  <div class='swiper swiper-products-{{type}}'>
    <div class='swiper-wrapper'>
      <ng-container *ngFor='let product of products; index as i; trackBy: trackByFn'>
        <a class='swiper-slide scp-products__product'
           [href]='product.link'
           *ngIf="product.link && !product.link.startsWith('/')">
          <picture>
            <img [src]='product.icon' alt='' height='24' loading='lazy' [class]='product.name' />
          </picture>
          <div>{{ product.name  }}</div>
        </a>
        <a class='swiper-slide scp-products__product'
           routerLink='{{ product.link }}'
           [routerLinkActiveOptions]='{ paths: product.link === "/" ? "exact" : "subset", queryParams: "subset", fragment: "ignored", matrixParams: "ignored"}'
           routerLinkActive='is-active'
           *ngIf="product.link.startsWith('/')">
          <picture>
            <img [src]='product.icon' alt='' height='24' loading='lazy' [class]='product.name' />
          </picture>
          <div>{{ product.name  }}</div>
        </a>
      </ng-container>
    </div>
  </div>
  <div class='scp-products__arrow scp-products__arrow__right' *ngIf='!reachEnd'>
    <button
      mat-icon-button
      (click)='swiper.slideNext()'
      [attr.aria-label]="'BUTTON.NEXT_PAGE' | translate">
      <img src='/assets/images/svg/arrow-2.svg' width='24' height='24' alt=''>
    </button>
  </div>
</div>
