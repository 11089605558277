import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '@scpc/modules/common/components/button/button.component';
import { SmartBannerService } from '@scpc/modules/common/components/smart-banner/smart-banner.service';
import { SmartBannerConfig } from '@scpc/dto';

@Component({
  selector: 'scp-smart-banner',
  templateUrl: './smart-banner.component.html',
  styleUrls: ['./smart-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
  ],
})
export class SmartBannerComponent implements OnInit {

  @Input()
  public logo: string;

  @Input()
  public config: SmartBannerConfig;

  @Input()
  public siteName: string;

  @Output()
  public close: EventEmitter<void> = new EventEmitter<void>();

  protected icon: string;
  protected title: string;
  protected url: string;

  constructor(private readonly smartBannerService: SmartBannerService) {
  }

  public ngOnInit() {
    if (this.smartBannerService.isAllowed(this.config)) {
      const platform: string = this.smartBannerService.getPlatform();
      if (platform === 'IPHONE') {
        this.title = `${this.siteName} App for iOS AppStore`;
        this.icon = 'app-store';
        this.url = this.config.iosUrl;
      } else if (platform === 'ANDROID') {
        this.title = `${this.siteName} App for Android`;
        this.icon = 'android';
        this.url = this.config.androidUrl;
      } else if (platform === 'HUAWEI') {
        this.title = `${this.siteName} App for AppGallery`;
        this.icon = 'app-gallery';
        this.url = this.config.androidUrl;
      } else if (platform === 'SAMSUNG') {
        this.title = `${this.siteName} App for Galaxy Store`;
        this.icon = 'galaxy-store';
        this.url = this.config.androidUrl;
      }
    }
  }

  protected hide() {
    this.smartBannerService.updateCloseTime();
    this.close.next();
  }

}
