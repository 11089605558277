import { ActivatedRouteSnapshot, Resolve, UrlSegment } from '@angular/router';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { ConfigService } from '@scpc/modules/common/services/config.service';
import { CmsService } from '@scpc/modules/common/services/cms.service';
import { Settings } from '@scpc/dto/settings';
import { ScpService, StorageService } from '@scpc/modules/common/services';
import { Customer, Profile } from '@scpc/dto/customer';
import { Banner } from '@scpc/modules/common/components/banners/models';
import { PromotionService } from '@scpc/modules/promotions/services/promotion.service';
import { InboxService } from '@scpc/modules/common/services/inbox.service';
import { SmartBannerConfig } from '@scpc/dto';
import { SmartBannerService } from '@scpc/modules/common/components/smart-banner/smart-banner.service';
import { SportsCartService } from '@scpc/modules/cart/services/sports-cart.service';

export interface PageData {
  title: string;
  description: string;
  seoText?: string;
  banners: Banner[];
  smartBanner?: SmartBannerConfig;
}

@Injectable({ providedIn: 'root' })
export class PageResolver implements Resolve<PageData> {

  constructor(private readonly cmsService: CmsService,
              private readonly scpService: ScpService,
              private readonly storageService: StorageService,
              private readonly configService: ConfigService,
              private readonly inboxService: InboxService,
              private readonly promotionService: PromotionService,
              private readonly smartBannerService: SmartBannerService,
              private readonly sportsCartService: SportsCartService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<PageData> {
    const isAuthorised = this.storageService.isAuthorised() && this.storageService.customerId && route.data.profile !== false;
    const product = route.data.product;
    const hasBanners: boolean = product && route.data.banners === true;
    const hasPromotion: boolean = isAuthorised && route.queryParams.promotion;
    const hasSmartBanner: boolean = route.data.smartBanner === true;
    return this.storageService.getCustomer().pipe(take(1), switchMap((customer: Customer | null) => (forkJoin([
        this.configService.initialized() ? of(null) : this.cmsService.getSettings(),
        this.cmsService.getPage(this.getPageUrl(route)).pipe(catchError(() => of([]))),
        isAuthorised && !customer
          ? this.scpService.getProfile()
          : of(null),
        hasBanners
          ? this.cmsService.getBanners(product).pipe(catchError(() => of([])))
          : of([]),
        hasPromotion
          ? this.promotionService.getPromotion(route.queryParams.promotion)
          : this.promotionService.clear(),
        hasSmartBanner && this.smartBannerService.isSupported()
          ? this.cmsService.getSmartBanner().pipe(catchError(() => of(null)))
          : of(null),
        this.sportsCartService.initialize(),
      ]).pipe(
        map((data: [Settings, PageData[], Profile, Banner[], void, SmartBannerConfig, void]) => {
          if (isAuthorised && !customer) {
            this.storageService.setCustomer(data[2].customer);
            this.storageService.setBalance(data[2].balance);
            this.inboxService.count = data[2].messages;
            this.storageService.setWithdrawalConfiguration(data[2].withdrawalConfiguration);
            this.storageService.setZendeskToken(data[2].zendeskToken);
          }
          if (data[0]) {
            this.configService.init(data[0]);
          }
          if (data[1].length) {
            return {
              title: data[1][0].title,
              description: data[1][0].description,
              seoText: data[1][0].seoText,
              banners: data[3],
              smartBanner: data[5],
            };
          }
          return {
            title: this.configService.defaultPageTitle,
            description: this.configService.defaultPageDescription,
            banners: data[3],
            smartBanner: data[5],
          };
        }))
    )));
  }

  public getPageUrl(route: ActivatedRouteSnapshot): string {
    const url = (
      '/' +
      route.pathFromRoot
        .filter((v: ActivatedRouteSnapshot) => !!v.url.length)
        .map((v: ActivatedRouteSnapshot) => v.url.map((segment: UrlSegment) => segment.path).join('/'))
        .join('/')
    );
    if (url.startsWith('/sports/events/')) {
      return '/sports/events';
    }
    return url.startsWith('/blog/pages') ? '/blog' : url;
  }

}
