import { Pipe, PipeTransform } from '@angular/core';
import { Money } from '@scpc/dto';
import { formatMoney } from '@scpc/utils/money.utils';

@Pipe({ name: 'formatMoney', standalone: true })
export class FormatMoneyPipe implements PipeTransform {
  public transform(value: Money, symbol: boolean = true, coins: boolean = true): string {
    if (!value) {
      return '';
    }
    return formatMoney(value, symbol, coins);
  }
}
