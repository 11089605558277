import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@scpc/modules/common/components/button/button.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'scp-header-cookies',
  templateUrl: './header-cookies.component.html',
  styleUrls: ['./header-cookies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, ButtonComponent]
})
export class HeaderCookiesComponent {

  @Output()
  public accepted: EventEmitter<void> = new EventEmitter<void>();

  constructor(private readonly router: Router) {
  }

  public acceptCookies(): void {
    this.accepted.next();
  }

  public async showMore(): Promise<void> {
    await this.router.navigateByUrl('/privacy-policy#9-cookies');
  }

}
