import { Injectable } from '@angular/core';
import { AsyncDialog } from '@scpc/modules/common/services/async-dialog';
import { DialogRef } from '@angular/cdk/dialog';
import { Bonus } from '@scpc/dto';
import { SourceOfFundsComponent } from '@scpc/modules/common/components/source-of-funds/source-of-funds.component';

@Injectable({ providedIn: 'root' })
export class SourceOfFundsDialogService extends AsyncDialog<(SourceOfFundsComponent),
  { isAuthorized: boolean },
  { emailBonus?: Bonus, numberOfBets?: number, review?: boolean }> {

  public async open(): Promise<DialogRef<unknown, SourceOfFundsComponent>> {
    const c = (await import('../source-of-funds/source-of-funds.component')).SourceOfFundsComponent;

    return this.dialog.open(c, {
      panelClass: ['scp-filter', 'scp-filter-without-margin'],
      backdropClass: 'scp-backdrop',
      maxHeight: 'calc(100% - 20px)',
      closeOnNavigation: false,
      disableClose: true,
      autoFocus: 'first-tabbable',
      maxWidth: 550,
      width: '100%',
    }).updatePosition() as DialogRef<unknown, SourceOfFundsComponent>;
  }

}
