import { Injectable } from '@angular/core';
import { ChatConfig, ReviewServiceConfig, Settings, SettingsProduct, Snippet } from '@scpc/dto/settings';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MetaDefinition } from '@angular/platform-browser';
import { environment } from '@scp-env/environment';

export interface Product {
  name: string;
  type: string,
  wagerTypes: string[];
  link: string;
  icon: string;
  hidden: boolean;
}

export interface MaintenanceData {
  text: string;
  url: string;
  urlText: string;
}

@Injectable({ providedIn: 'root' })
export class ConfigService {

  public readonly initialize: Subject<boolean> = new BehaviorSubject(false);

  private settings: Settings = null;
  private googleTagManager: Subject<string | null> = new BehaviorSubject(null);

  public get affiliateProgram(): string | undefined {
    return this.settings.affiliateProgram;
  }

  public get meta(): MetaDefinition[] {
    return this.settings.meta;
  }

  public get siteId(): number {
    return this.settings.siteId;
  }

  public get operationId(): number {
    return this.settings.operationId;
  }

  public get siteName(): string {
    return this.settings.siteName;
  }

  public get supportEmail(): string {
    return this.settings.supportEmail;
  }

  public get normalizedSiteName(): string {
    return this.settings.siteName.toLowerCase().replace(' ', '-');
  }

  public get supportChats(): ChatConfig[] {
    return this.settings.supportChats || /* istanbul ignore next */ [];
  }

  public get ficaEmail(): string {
    return this.settings.ficaEmail;
  }

  public get footer(): string | null {
    return this.settings.footer;
  }

  public get country(): string {
    return this.settings.country;
  }

  public get bookmakerLicense(): string {
    return this.settings.license;
  }

  public get googleAccessKey(): string {
    return this.settings.googleAccessKey;
  }

  public get googleTagManagerId(): Observable<string | null> {
    return this.googleTagManager;
  }

  public get defaultPageTitle(): string {
    return this.settings.defaultPageTitle;
  }

  public get defaultPageDescription(): string {
    return this.settings.defaultPageDescription;
  }

  public get hasTerms(): boolean {
    return this.settings.terms;
  }

  public get hasPoPIA(): boolean {
    return this.settings.popia;
  }

  public get hasRules(): boolean {
    return this.settings.rules;
  }

  public get hasPrivacyPolicy(): boolean {
    return this.settings.privacyPolicy;
  }

  public get hasContacts(): boolean {
    return this.settings.contacts;
  }

  public get hasBlog(): boolean {
    return this.settings.blog;
  }

  public get hasAboutUs(): boolean {
    return this.settings.aboutUs;
  }

  public get hasResponsibleGambling(): boolean {
    return this.settings.responsibleGambling;
  }

  public get hasFAQ(): boolean {
    return this.settings.faq;
  }

  public get hasCares(): boolean {
    return this.settings.cares;
  }

  public get hasRegulations(): boolean {
    return this.settings.regulations;
  }

  public get fingerprintDomain(): string | null {
    return this.settings.fingerprintDomain;
  }

  public get fingerprintToken(): string | null {
    return this.settings.fingerprintToken;
  }

  public get aCoinUrl(): string | null {
    return this.settings.aCoinUrl || 'https://acoin.co.za/purchase';
  }

  public get fingerprintEnabled(): boolean {
    return coerceBooleanProperty(this.settings.fingerprintEnabled);
  }

  public get snippets(): Snippet[] {
    return this.settings.snippets;
  }

  public get reviews(): ReviewServiceConfig[] {
    return this.settings.reviews;
  }

  public get products(): Product[] {
    function getLink(type: string): string {
      switch (type) {
        case 'LOBBY':
          return '/';
        case 'LUCKY_NUMBERS':
          return '/lucky-numbers';
        case 'BET_GAMES':
          return '/betgames';
        case 'SPORTS':
          return '/sports';
        case 'LIVE_GAMES':
          return '/live-games';
        case 'SLOTS':
          return '/slots';
        case 'FAFI':
          return '/fafi';
        case 'PROMOTIONS':
          return '/promotions';
      }
      return '/';
    }

    function getIcon(type: string): string {
      switch (type) {
        case 'LOBBY':
          return 'lobby';
        case 'LUCKY_NUMBERS':
          return 'lucky';
        case 'BET_GAMES':
          return 'betgames';
        case 'LIVE_GAMES':
          return 'live-games';
        case 'SLOTS':
          return 'slots';
        case 'SPORTS':
          return 'sports';
        case 'FAFI':
          return 'fafi';
        case 'PROMOTIONS':
          return 'promotions';
      }
      return '';
    }

    return this.settings.products.map((product: SettingsProduct) => {
        return {
          name: product.name,
          type: product.type,
          wagerTypes: product.wagerType ? product.wagerType.split(',') : [],
          link: product.link || getLink(product.type),
          icon: product.icon?.url || `/assets/images/svg/${getIcon(product.type)}.svg`,
          hidden: product.hidden === true,
        };
      },
    );
  }

  public static getProduct(wagerType: string): string | null {
    switch (wagerType) {
      case 'LOTTO_WAGER':
        return 'LUCKY_NUMBERS';
      case 'BETGAMES_WAGER':
        return 'BETGAMES';
      case 'PRAGMATIC_WAGER':
      case 'EVOLUTION_WAGER':
      case 'EZUGI_WAGER':
      case 'HB_WAGER':
        return 'LIVE_GAMES';
      case 'PRAGMATIC_SLOT_WAGER':
      case 'EVOLUTION_SLOT_WAGER':
      case 'EZUGI_SLOT_WAGER':
      case 'HB_SLOT_WAGER':
      case 'GMW_SLOT_WAGER':
        return 'SLOTS';
      case 'LIVE_WAGER':
      case 'WAGER':
      case 'BETTING_WAGER':
        return 'SPORTS';
      case 'FAFI_WAGER':
        return 'FAFI';
    }
    return null;
  }

  public static getProductIcon(wagerType: string): string | null {
    switch (wagerType) {
      case 'LOTTO_WAGER':
        return 'lucky';
      case 'BETGAMES_WAGER':
        return 'betgames';
      case 'PRAGMATIC_WAGER':
      case 'EVOLUTION_WAGER':
      case 'EZUGI_WAGER':
      case 'HB_WAGER':
        return 'live-games';
      case 'PRAGMATIC_SLOT_WAGER':
      case 'EVOLUTION_SLOT_WAGER':
      case 'EZUGI_SLOT_WAGER':
      case 'HB_SLOT_WAGER':
      case 'GMW_SLOT_WAGER':
        return 'slots';
      case 'LIVE_WAGER':
      case 'WAGER':
      case 'BETTING_WAGER':
        return 'sports';
      case 'FAFI_WAGER':
        return 'fafi';
      case 'PROMOTIONS':
        return 'promotions';
    }
    return null;
  }

  public init(settings: Settings) {
    this.settings = settings;
    this.initialize.next(true);
    this.googleTagManager.next(this.settings.googleTagManagerId);
  }

  public initialized() {
    return this.settings !== null;
  }

  /* istanbul ignore next */
  public getLuckyNumbersMaintenanceData(): MaintenanceData | null {
    if (environment.isLuckyNumbersMaintenance) {
      return {
        text:
          '<p>Kindly note that we are busy with some improvements to our Lucky Numbers.</p>' +
          '<br>' +
          '<p>Yes, the out-of-play is only temporary!</p>' +
          '<p>Yes, we are working to better your experience with us!</p>' +
          '<br>' +
          '<p>Expected duration of downtime will be from 23:00, 10 January 2023 until 9:00, 11 January 2023.</p>',
        url: '/',
        urlText: 'Go to Top Page',
      };
    }
    return null;
  }

}
