export function getFirstElementByTagName(document: Document, name: string): Element {
  return document.getElementsByTagName(name)[0];
}

export function getElementsByClassName(document: Document, name: string): Element {
  return document.getElementsByClassName(name)[0];
}

export function createPreloadLink(document: Document, url: string, media?: string): HTMLLinkElement {
  const link: HTMLLinkElement = document.createElement('link');
  link.rel = 'preload';
  link.href = url;
  // link.type = 'image/' + url.split('.').pop();
  link.setAttribute('as', 'image');
  if (media) {
    link.media = media;
  }
  return link;
}

export function scrollTo(document: Document, id: string, behavior: ScrollBehavior = 'smooth'): void {
  setTimeout((): void => {
    try {
      document.getElementById(id).scrollIntoView({ behavior });
    } catch (e) { /* empty */
    }
  });
}

export function catchError(f: () => void): void {
  try {
    f();
  } catch (e) { /* empty */
  }
}
