<div
  class='scp-notification'
  [class.scp-notification-error]="data.type === 'ERROR'"
  [class.scp-notification-success]="data.type === 'SUCCESS'"
>
  <div class='scp-notification-icon'></div>
  <div
    class='scp-notification-message'
    *ngIf='data.message'
    [innerHTML]='sanitizer.bypassSecurityTrustHtml(data.message | translate: data.messageParams)' scpRouteTransformer
  ></div>
  <button mat-icon-button (mousedown)='close($event)' class='scp-close-notification'>
    <img
      src='/assets/images/svg/error-close.svg'
      *ngIf="data.type === 'ERROR'"
      alt=''
      height='16'
      width='16'
      loading='lazy'
    />
    <img
      src='/assets/images/svg/success-close.svg'
      *ngIf="data.type === 'SUCCESS'"
      alt=''
      height='16'
      width='16'
      loading='lazy'
    />
  </button>
</div>
