import {
  APP_ID,
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
} from '@angular/core';
import { BrowserCookiesModule } from '@scpc/modules/cookies/browser/browser-cookies.module';
import {
  AUTH_SERVICE,
  AuthModule,
  PROTECTED_FALLBACK_PAGE_URI,
  PUBLIC_FALLBACK_PAGE_URI,
} from '@scpc/modules/auth/common';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient } from '@angular/common/http';
import { TourTippyModule } from '@scpc/modules/common/tour/tippy-tour.module';
import { DialogModule } from '@angular/cdk/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideServiceWorker } from '@angular/service-worker';
import { environment } from '@scp-env/environment';
import { provideRouter, withEnabledBlockingInitialNavigation, withInMemoryScrolling } from '@angular/router';
import { ROUTES } from '@scpc/app.routing';
import { AuthenticationService } from '@scpc/modules/common/services';
import { SwInterceptor } from '@scpc/modules/common/interceptors/sw.interceptor';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MultiTranslateHttpLoader } from '@scpc/modules/common/services/multi-http-loader';
import { firstValueFrom } from 'rxjs';
import { provideClientHydration } from '@angular/platform-browser';

/* istanbul ignore next */
export class ScpMissingTranslationHandler implements MissingTranslationHandler {

  public handle(params: MissingTranslationHandlerParams) {
    return params.key;
  }

}

/* istanbul ignore next */
function appInitializerFactory(translate: TranslateService) {
  return async () => {
    translate.setDefaultLang('en');
    return firstValueFrom(translate.use('en'));
  };
}

export const config: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(),
    provideServiceWorker('ngsw-worker.js', { enabled: environment.production }),
    provideRouter(ROUTES,
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
      }),
    ),
    provideClientHydration(),
    importProvidersFrom(
      BrowserCookiesModule.forRoot({ path: '/', secure: window.location.protocol.startsWith('https') }),
      AuthModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: /* istanbul ignore next */ () => new MultiTranslateHttpLoader(),
          deps: [HttpClient],
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: ScpMissingTranslationHandler,
        },
      }),
      TourTippyModule.forRoot(),
      DialogModule,
      MatSnackBarModule,
      LoadingBarHttpClientModule,
      LoadingBarRouterModule,
    ),
    AuthenticationService,
    { provide: APP_ID, useValue: 'scp-app' },
    { provide: PROTECTED_FALLBACK_PAGE_URI, useValue: '/' },
    { provide: PUBLIC_FALLBACK_PAGE_URI, useValue: '/sign-in' },
    { provide: AUTH_SERVICE, useFactory: /* istanbul ignore next */ (auth) => auth, deps: [AuthenticationService] },
    { provide: HTTP_INTERCEPTORS, useClass: SwInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: appInitializerFactory, deps: [TranslateService], multi: true },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { terminateOnPointerUp: false } },
  ],
};
