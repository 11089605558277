<ng-template #tourStep>
  <div class='scp-tour-step' (click)='$event.preventDefault(); $event.stopPropagation()'
       *ngIf='tourService.currentStep?.content'>
    <p class='scp-tour-step__content'
       [innerHTML]='domSanitizer.bypassSecurityTrustHtml(translateService.instant(tourService.currentStep.content))'></p>
    <div class='scp-tour-step__navigation' *ngIf='tourService.hasNext(tourService.currentStep)'>
      <button (click)='end($event);' class='scp-tour-step__close'>
        {{(tourService.currentStep.endBtnTitle || 'TOUR.CLOSE') | translate}}
      </button>
      <button (click)='next($event)' class='scp-tour-step__next'>
        <span style='display: flex; align-items: center;'>
          {{(tourService.currentStep.nextBtnTitle || 'TOUR.NEXT') | translate}}
          <img src='/assets/images/svg/back.svg' alt='' height='14' width='8'>
        </span>
      </button>
    </div>
  </div>
  <div class='scp-tour-step__actions' *ngIf='tourService.currentStep && !tourService.hasNext(tourService.currentStep)'>
    <button class='scp-tour-step__actions__done'
            (click)='tourService.steps.length === 1 ? pause($event) : end($event)'>
      {{(tourService.currentStep.endBtnTitle || 'TOUR.DONE') | translate}}
    </button>
  </div>
</ng-template>
